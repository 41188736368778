import { differenceInMonths } from 'date-fns'

import { CurrentUserBannerPartsFragment } from 'gql'

type shouldShowRenewalBannerArgs = {
  activeSubscription: {
    isExpired?: boolean | null
    daysLeft: number
    isDunning: boolean
  }
  isCanceling: boolean
  isRenewalBannerDismissed: boolean
  isBilledViaInvoice: boolean
  hasOpenInvoices: boolean
}

export const getShouldShowRenewalBanner = ({
  activeSubscription,
  isCanceling,
  isRenewalBannerDismissed,
  isBilledViaInvoice,
  hasOpenInvoices
}: shouldShowRenewalBannerArgs): boolean => {
  return !!(
    !isRenewalBannerDismissed &&
    !isCanceling &&
    !activeSubscription.isExpired &&
    (activeSubscription.daysLeft <= 30 ||
      (isBilledViaInvoice && hasOpenInvoices && !activeSubscription.isDunning))
  )
}

export function shouldShowUpdateCompanyBanner(
  user: CurrentUserBannerPartsFragment,
  isSubOwner: boolean
) {
  // Only show for members and subscription owners
  if (!user.is.member || !isSubOwner) return false

  const today = new Date()
  const MONTHS_BEFORE_STALE = 12

  // Don't show for accounts created less than 12 months ago
  if (
    user.createdAt &&
    differenceInMonths(today, new Date(user.createdAt)) <= MONTHS_BEFORE_STALE
  ) {
    return false
  }

  // Otherwise, show to users who have not dismissed the banner or updated their company name
  if (!user.dismissedUpdateCompanyBannerAt && !user.companyNameUpdatedAt) {
    return true
  }

  const dimissedDate = new Date(user.dismissedUpdateCompanyBannerAt || today)
  const updatedDate = new Date(user.companyNameUpdatedAt || today)

  if (!user.dismissedUpdateCompanyBannerAt) {
    return differenceInMonths(today, updatedDate) >= MONTHS_BEFORE_STALE
  }

  if (!user.companyNameUpdatedAt) {
    return differenceInMonths(today, dimissedDate) >= MONTHS_BEFORE_STALE
  }

  return (
    differenceInMonths(today, dimissedDate) >= MONTHS_BEFORE_STALE &&
    differenceInMonths(today, updatedDate) >= MONTHS_BEFORE_STALE
  )
}
