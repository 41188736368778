import clsx from 'clsx'
import { useState } from 'react'

import { cn } from 'utils/tailwind'

import { ReactComponent as ArrowLeft } from 'images/arrow-left.svg'

import iconCreativeBrief from '../icons/creative-brief.png'
import iconDecisionMakingFramework from '../icons/decision-making-framework.png'
import iconFeatureMap from '../icons/feature-map.png'
import iconFeaturePrioritization from '../icons/feature-prioritization.png'
import iconGtm from '../icons/gtm.png'
import iconJobDescription from '../icons/job-description.png'
import iconOnboardingPlan from '../icons/onboarding-plan.png'
import iconPmfNarrative from '../icons/pmf-narrative.png'
import iconPrd from '../icons/prd.png'
import iconProductBrief from '../icons/product-brief.png'
import iconProductMessaging from '../icons/product-messaging.png'
import iconProductPositioning from '../icons/product-positioning.png'
import iconProductRoadmap from '../icons/product-roadmap.png'
import iconProductStrategyMap from '../icons/product-strategy-map.png'
import iconProjectPlan from '../icons/project-plan.png'
import iconRetentionEngagementAnalysis from '../icons/retention-engagement-analysis.png'
import iconTechSpec from '../icons/tech-spec.png'
import iconUseCaseMap from '../icons/use-case-map.png'
import iconUserTestPlan from '../icons/user-test-plan.png'
import iconVisionNarrative from '../icons/vision-narrative.png'

export const iconMappings: Record<string, string> = {
  'creative-brief': iconCreativeBrief,
  'decision-making-framework': iconDecisionMakingFramework,
  'feature-map': iconFeatureMap,
  'feature-prioritization': iconFeaturePrioritization,
  'gtm': iconGtm,
  'job-description': iconJobDescription,
  'onboarding-plan': iconOnboardingPlan,
  'pmf-narrative': iconPmfNarrative,
  'prd': iconPrd,
  'product-brief': iconProductBrief,
  'product-messaging': iconProductMessaging,
  'product-positioning': iconProductPositioning,
  'product-roadmap': iconProductRoadmap,
  'project-plan': iconProjectPlan,
  'retention-engagement-analysis': iconRetentionEngagementAnalysis,
  'tech-spec': iconTechSpec,
  'use-case-map': iconUseCaseMap,
  'user-test-plan': iconUserTestPlan,
  'vision-narrative': iconVisionNarrative,
  'product-strategy-map': iconProductStrategyMap
}

type CategoryName = 'all' | 'product' | 'marketing' | 'other'

type Draft = {
  id: number
  name: string
  description: string
  templateKind: string
  promptKey: string
  categories: CategoryName[]
}

const drafts: {
  id: number
  name: string
  description: string
  templateKind: string
  promptKey: string
  categories: CategoryName[]
}[] = [
  {
    id: 1,
    name: 'PMF Narrative',
    description: 'Articulate how your product idea will work in practice',
    templateKind: 'pmf-narrative',
    promptKey: 'ai-pmf-narrative-template-prompt',
    categories: ['product', 'marketing']
  },
  {
    id: 2,
    name: 'Product Strategy Map',
    description: 'Create a strategy that drives meaningful results',
    templateKind: 'product-strategy-map',
    promptKey: 'ai-product-strategy-map-template-prompt',
    categories: ['product']
  },
  {
    id: 3,
    name: 'Product Problem Definition Brief',
    description: 'Define product problems and solutions',
    templateKind: 'product-brief',
    promptKey: 'ai-artifacts-draft-template-prompt',
    categories: ['product']
  },
  {
    id: 4,
    name: '4D Roadmap',
    description: 'Articulate vision and milestones for product',
    templateKind: 'product-roadmap',
    promptKey: 'ai-4-d-product-roadmap-template-prompt',
    categories: ['product']
  },
  {
    id: 5,
    name: 'Decision-Making Matrix',
    description: 'Standardize your decision-making process',
    templateKind: 'decision-making-framework',
    promptKey: 'ai-decision-making-matrix-template-prompt',
    categories: ['product', 'other']
  },
  {
    id: 6,
    name: 'Feature Map',
    description: 'Define, prioritize, and evaluate features',
    templateKind: 'feature-map',
    promptKey: 'ai-feature-map-template-prompt',
    categories: ['product']
  },
  {
    id: 7,
    name: 'GTM Launch Plan',
    description: 'Strategic roadmap for successful product launch',
    templateKind: 'gtm',
    promptKey: 'ai-gtm-launch-plan-hubspot-template-prompt',
    categories: ['marketing']
  },
  {
    id: 8,
    name: 'PRD',
    description: 'Align teams, clarify goals and guide product success',
    templateKind: 'prd',
    promptKey: 'ai-prd-template-prompt',
    categories: ['product']
  },
  {
    id: 9,
    name: 'Vision Narrative',
    description: 'Convey a product vision',
    templateKind: 'vision-narrative',
    promptKey: 'ai-vision-narrative-template-prompt',
    categories: ['product']
  },
  {
    id: 10,
    name: 'Use Case Map',
    description: 'Visualize user journeys and product applications',
    templateKind: 'use-case-map',
    promptKey: 'ai-use-case-map-template-prompt',
    categories: ['product', 'marketing']
  },
  {
    id: 11,
    name: 'User Test Plan',
    description: 'Validate product usability with target users',
    templateKind: 'user-test-plan',
    promptKey: 'ai-user-test-plan-template-prompt',
    categories: ['product']
  },
  {
    id: 12,
    name: 'Retention Diagnosis',
    description: 'Identify and address causes of customer churn',
    templateKind: 'retention-engagement-analysis',
    promptKey: 'ai-retention-engagement-diagnosis-template-prompt',
    categories: ['product']
  }
]

const categories: { name: CategoryName }[] = [
  { name: 'all' },
  { name: 'product' },
  { name: 'marketing' },
  { name: 'other' }
]

export const DraftList = ({
  onBackClick,
  onDraftClick,
  isExpanded
}: {
  onDraftClick: ({
    promptKey,
    draftName
  }: {
    promptKey: string
    draftName: string
  }) => void
  onBackClick: () => void
  isExpanded: boolean
}) => {
  const [activeCategory, setActiveCategory] = useState<CategoryName>('all')
  const [filteredDrafts, setFilteredDrafts] = useState(drafts)

  const setActive = (categoryName: CategoryName) => {
    if (categoryName) {
      setActiveCategory(categoryName)
      setFilteredDrafts(
        categoryName === 'all'
          ? drafts
          : drafts.filter((draft) => draft.categories.includes(categoryName))
      )
    }
  }

  const handleDraftSelect = (draft: Draft) => {
    onDraftClick({ promptKey: draft.promptKey, draftName: draft.name })
  }

  return (
    <div
      className={clsx(
        'flex flex-col bg-white rounded-t-2xl border border-gray-200 shadow-md h-[calc(100%_-_3rem)] absolute bottom-0 left-0 right-0 md:max-w-3xl lg:max-w-4xl',
        {
          'mb-8 rounded-b-2xl': isExpanded
        }
      )}
    >
      <ul className="flex overflow-x-scroll hide-scrollbar list-none p-4 m-0">
        {categories.map((category) => (
          <li key={category.name}>
            <button
              onClick={() => setActive(category.name)}
              className={cn(
                'transition-colors duration-200 bg-white p-[10px] mr-2 rounded-lg font-medium text-sm leading-[12px] text-[#787878] hover:bg-[#f5f5f5] capitalize max-w-24 truncate',
                {
                  'bg-[#f5f5f5] text-[#0C0F0D]': activeCategory === category.name
                }
              )}
            >
              {category.name}
            </button>
          </li>
        ))}
      </ul>
      <div className="p-2 space-y-2 overflow-y-auto mb-2">
        {filteredDrafts.map((draft, idx) => (
          <button
            key={draft.id}
            id={`draftItem${idx}`}
            onClick={() => handleDraftSelect(draft)}
            className="w-full text-left outline-none p-2 text-base leading-[19px] transition-colors duration-200 rounded-xl bg-white hover:bg-[#F5F5F5] focus:bg-[#F5F5F5]"
          >
            <div className="flex">
              <img
                src={iconMappings[draft.templateKind] || iconProductBrief}
                className="w-[72px] h-[72px] mr-4 shrink-0 rounded-lg"
                alt={draft.name}
              />
              <div
                className={clsx('grow', {
                  'max-w-[250px]': !isExpanded
                })}
              >
                <h3
                  className="font-medium leading-[150%] mb-0 truncate"
                  title={draft.name}
                >
                  {draft.name}
                </h3>
                <p className="text-[#787878] leading-[140%] text-xs mb-0">
                  {draft.description}
                </p>
              </div>
            </div>
          </button>
        ))}
      </div>
      <button
        className="w-full text-left z-10 h-[64px] p-4 text-base flex items-center border-t border-gray-200 mt-auto shrink-0"
        onClick={onBackClick}
      >
        <ArrowLeft width={16} className="mr-2" />
        Back
      </button>
    </div>
  )
}
