import { useLocation } from 'react-router-dom'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { useGlobalChat } from './GlobalChatProvider'
import { GlobalChatUI } from './GlobalChatUI'

interface GlobalChatProps {
  renderStyle: 'draggable' | 'side_panel'
}

export function GlobalChat({ renderStyle }: GlobalChatProps) {
  const { pathname } = useLocation()
  const isMobile = useMediaQuery('(max-width: 671px)')
  const { currentUser } = useCurrentUser()
  const globalChatProps = useGlobalChat()

  return (
    <GlobalChatUI
      {...globalChatProps}
      currentUser={currentUser}
      isMobile={isMobile}
      pathname={pathname}
      renderStyle={renderStyle}
    />
  )
}
