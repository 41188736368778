import { FC } from 'react'

export const SLIDE_TRANSITION_TIME = 500 // ms
export const SIDE_PANEL_BREAKPOINT = 1100 // px
export const SIDE_PANEL_WIDTH = 417 // px

export const SidePanel: FC = ({ children }) => {
  return (
    <div
      style={{ width: SIDE_PANEL_WIDTH }}
      className="flex items-stretch h-full ml-8 pl-8 py-8 border-l border-l-gray"
    >
      {children}
    </div>
  )
}
