import { DraggableSyntheticListeners } from '@dnd-kit/core'
import { FC } from 'react'

import Button from 'components/Button'
import Tooltip from 'components/Tooltip/Tooltip'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as CloseIcon } from 'images/icon--close.svg'
import { ReactComponent as ExpandIcon } from 'images/icon--expand-modal.svg'
import { ReactComponent as HamburgerIcon } from 'images/icon--hamburger.svg'
import { ReactComponent as ShrinkIcon } from 'images/icon--shrink-modal.svg'
import { ReactComponent as PencilWriteIcon } from 'images/pencil-write-icon-2.svg'

interface HeaderMenuProps {
  className?: string
  isfullScreen: boolean
  listeners?: DraggableSyntheticListeners
  onClose: () => void
  onExpand: () => void
  onShrink: () => void
  menuSideBarIsOpen: boolean
  onMenuSideBarOpen: () => void
  onMenuSideBarClose: () => void
  startNewChat: () => void
  activeSession: boolean
}

export const HeaderMenu: FC<HeaderMenuProps> = ({
  className,
  isfullScreen = false,
  onClose,
  onExpand,
  onShrink,
  startNewChat,
  menuSideBarIsOpen,
  onMenuSideBarOpen,
  onMenuSideBarClose,
  listeners,
  activeSession
}) => {
  const isMobile = useMediaQuery('(max-width: 671px)')

  return (
    <div
      className={cn('flex items-center gap-2 border-b border-rb-gray px-2', className)}
    >
      <div className="relative flex gap-2 items-center">
        <Tooltip
          place="top"
          html={true}
          delayUpdate={300}
          delayHide={0}
          delayShow={120}
          className="!opacity-100"
          tooltipBody="See History"
        >
          <Button
            variant="text-only"
            className="h-8 w-8 shrink-0 p-0"
            size="x-small"
            onClick={(e) => {
              e.stopPropagation()

              menuSideBarIsOpen ? onMenuSideBarClose() : onMenuSideBarOpen()
            }}
          >
            <HamburgerIcon className="h-4 w-4 fill-rb-gray-400" />
          </Button>
        </Tooltip>
        <Tooltip
          place="top"
          html={true}
          delayUpdate={300}
          delayHide={0}
          delayShow={120}
          className="!opacity-100"
          tooltipBody="New Thread"
        >
          <button onClick={startNewChat} className="h-5 w-5 mb-0.5">
            <PencilWriteIcon
              className={!activeSession ? 'fill-rb-gray-400' : 'fill-[#B5B8B4]'}
            />
          </button>
        </Tooltip>
      </div>
      <div
        className={cn('flex w-full items-center gap-2 h-full', {
          'cursor-grab': !!listeners
        })}
        {...listeners}
      ></div>
      <div className="flex shrink-0 gap-x-3">
        {!isMobile && (
          <div className="relative">
            <Tooltip
              place="top"
              html={true}
              delayUpdate={300}
              delayHide={0}
              delayShow={120}
              className="!opacity-100"
              tooltipBody={isfullScreen ? 'Shrink' : 'Expand'}
            >
              <Button
                variant="text-only"
                className="h-8 w-8 shrink-0 p-0"
                size="x-small"
                onClick={(e) => {
                  e.stopPropagation()
                  isfullScreen ? onShrink() : onExpand()
                }}
              >
                {isfullScreen ? <ShrinkIcon width={16} /> : <ExpandIcon width={14} />}
              </Button>
            </Tooltip>
          </div>
        )}
        <Tooltip
          place="top"
          html={true}
          delayUpdate={300}
          delayHide={0}
          delayShow={120}
          className="!opacity-100"
          tooltipBody="Close"
        >
          <Button
            variant="text-only"
            className="h-8 w-8 shrink-0 p-0"
            size="x-small"
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          >
            <CloseIcon width={16} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
