import { Editor as EditorType } from '@tiptap/react'
import clsx from 'clsx'
import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Editor } from 'domains/Ai/Chat/Editor/Editor'

import { SIDE_PANEL_BREAKPOINT } from 'components/SidePanel'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import MembershipCallout from 'components/dopt/MembershipCallout'
import { BasicInfoIcon, CloseIcon, CopyIcon } from 'components/icons'

import { AiDocument } from 'gql'

import { UserContextType } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import notifyError from 'utils/errorNotifier'
import {
  trackAiChatExpanded,
  trackAiChatMinimized as trackAiChatShrinked,
  trackCopiedText
} from 'utils/tracking/analytics'

import { ChatFeed } from './Chat/ChatFeed'
import { ChatWrapper } from './Chat/ChatWrapper'
import { MenuSideBar } from './Chat/MenuSideBar'
import PersonalizationCta from './Chat/PersonalizationCta'
import { Personalize, usePersonalizationConfirmationModal } from './Chat/Personalize'
import { UserInput } from './Chat/UserInput'
import { DraftList } from './Chat/UserInput/DraftList'
import { GlobalChatContextProps, isOnSidePanelChatPage } from './GlobalChatProvider'
import { useGlobalChatTracking } from './GlobalChatTrackingProvider'
import { Message } from './types'

const NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN = 3

export type GlobalChatUIProps = GlobalChatContextProps & {
  isMobile: boolean
  pathname: string
  currentUser: UserContextType['currentUser']
  renderStyle: 'draggable' | 'side_panel'
}

export interface OnGeneratedDraftCtaClickProps {
  message: Message
  title: string
  htmlString: string
}

export const GlobalChatUI: React.FC<GlobalChatUIProps> = ({
  chatId,
  isChatOpen,
  isSidePanelChatOpenDelayed,
  messages,
  isExpanded,
  isLoading,
  loadingSession,
  reload,
  recentChatsCount,
  showPersonalizationCta,
  dismissPersonalizationCta,
  toggle,
  setIsExpanded,
  minimize,
  loadSession,
  newSession,
  editor,
  editorHeaderTitle,
  setEditorHeaderTitle,
  showEditDraftView,
  setShowEditDraftView,
  resetMode,
  dedupedTopicsAndFunctions,
  dedupedTopicsAndFunctionsLoading,
  personalizationData,
  personalizationLoading,
  menuSideBarIsOpen,
  setMenuSideBarIsOpen,
  populateEditor,
  personalizeActive,
  setPersonalizeActive,
  activeDraft,
  clearAndCloseEditDraftView,
  mode,
  setMode,
  stopGeneration,
  isMobile,
  pathname,
  currentUser,
  sendMessage,
  suggestedPrompts,
  suggestedPromptsLoading,
  activeSession,
  showDraftMenu,
  setShowDraftMenu,
  renderStyle,
  isSidePanelChatOpen
}) => {
  const location = useLocation()
  const { aiEmbeddedOnHomepage } = useFeatureFlags()
  const [personalizationFormIsDirty, setPersonalizationFormIsDirty] = useState(false)
  const { trackChatDraftClicked } = useGlobalChatTracking()

  const isFullScreen = isExpanded || isMobile

  const { requestConfirmation } = usePersonalizationConfirmationModal()

  const isFreeUser = currentUser?.is.freeUser ?? false

  const onNewChatClick = useCallback(() => {
    function resetChat() {
      if (!isFullScreen || isMobile) setMenuSideBarIsOpen(false)
      if (messages.length > 0) newSession({ ctaText: 'new_chat_button' })
      setPersonalizeActive(false)
      resetMode()
      setShowDraftMenu(false)
    }

    if (personalizationFormIsDirty) {
      requestConfirmation(() => {
        setPersonalizationFormIsDirty(false)
        resetChat()
        setShowEditDraftView(false)
      })
    } else {
      resetChat()
      setShowEditDraftView(false)
    }
  }, [
    messages.length,
    personalizationFormIsDirty,
    isFullScreen,
    isMobile,
    requestConfirmation,
    setShowEditDraftView,
    resetMode,
    setMenuSideBarIsOpen,
    setPersonalizeActive,
    setShowDraftMenu,
    newSession
  ])

  const handleMinimize = useCallback(() => {
    if (personalizationFormIsDirty) {
      requestConfirmation(() => {
        setPersonalizationFormIsDirty(false)
        setPersonalizeActive(false)
        minimize()
        setIsExpanded(false)
        setMenuSideBarIsOpen(false)
      })
    } else {
      minimize()
      setPersonalizeActive(false)
      setIsExpanded(false)
      setMenuSideBarIsOpen(false)
    }

    setShowEditDraftView(false)
    setEditorHeaderTitle('')

    setShowDraftMenu(false)
  }, [
    minimize,
    setIsExpanded,
    requestConfirmation,
    personalizationFormIsDirty,
    setShowEditDraftView,
    setEditorHeaderTitle,
    setMenuSideBarIsOpen,
    setPersonalizeActive,
    setShowDraftMenu
  ])

  const handleExpand = useCallback(() => {
    setIsExpanded(true)
    trackAiChatExpanded({
      chat_session_id: chatId,
      path: pathname,
      access_policy_kind: currentUser?.accessPolicyKind,
      mode: mode.mode
    })

    setMenuSideBarIsOpen(true)
  }, [
    setIsExpanded,
    chatId,
    pathname,
    currentUser?.accessPolicyKind,
    setMenuSideBarIsOpen,
    mode
  ])

  const onGeneratedDraftCtaClick = ({
    message,
    htmlString
  }: OnGeneratedDraftCtaClickProps) => {
    trackChatDraftClicked({
      chatId,
      draftId: message.aiDocument?.id,
      templateName: message.modeOptions?.label,
      location: 'in_chat'
    })

    if (!message.aiDocument) {
      notifyError('Error while extracting draft from message', {
        messageId: message.id
      })
      return
    }

    populateEditor({ draft: message.aiDocument as AiDocument, htmlString })
  }

  const handlePersonalizationCancel = () => {
    if (personalizationFormIsDirty) {
      requestConfirmation(() => {
        setPersonalizeActive(false)
        setPersonalizationFormIsDirty(false)
      })
      return
    }

    if (!isFullScreen) {
      setMenuSideBarIsOpen(true)
    }

    setPersonalizeActive(false)
  }

  const handlePersonalizationSave = () => {
    setPersonalizeActive(false)
    setPersonalizationFormIsDirty(false)
  }

  const Layout = showEditDraftView ? DraftGenerationLayout : ChatViewLayout

  const isOnSidePanelPage = isOnSidePanelChatPage(location.pathname)
  const isAboveSidePanelMinWidth = useMediaQuery(
    `(min-width: ${SIDE_PANEL_BREAKPOINT}px)`
  )

  if (renderStyle === 'side_panel') {
    if (
      !aiEmbeddedOnHomepage || // feature flag isn't enabled
      !isOnSidePanelPage || // not on a page that has a side panel
      !isSidePanelChatOpenDelayed || // side panel isn't open (with delay for animation)
      !currentUser // user isn't logged in
    ) {
      return null
    }
  }

  if (renderStyle === 'draggable') {
    if (
      (isOnSidePanelPage && isAboveSidePanelMinWidth) || // on a page that has a side panel & there is enough space on the page to render side panel
      !isChatOpen || // chat isn't open
      !currentUser // user isn't logged in
    ) {
      return null
    }
  }

  return (
    <div id="ai-chat-container" className="w-full">
      <ChatWrapper
        isFullScreen={isFullScreen}
        isSidePanelChatOpen={isSidePanelChatOpen}
        showHeaderMenu={true}
        onExpand={handleExpand}
        startNewChat={onNewChatClick}
        renderStyle={renderStyle}
        onShrink={() => {
          setIsExpanded(false)
          setMenuSideBarIsOpen(false)

          // To avoid UI weirdness in showing editor in small view
          // we just hide the editor when shrinking
          setShowEditDraftView(false)

          trackAiChatShrinked({
            chat_session_id: chatId,
            path: pathname,
            access_policy_kind: currentUser?.accessPolicyKind
          })
        }}
        onClose={handleMinimize}
        onMenuSideBarOpen={() => {
          if (personalizeActive && !isFullScreen && personalizationFormIsDirty) {
            requestConfirmation(() => {
              setPersonalizeActive(false)
              setPersonalizationFormIsDirty(false)
              setMenuSideBarIsOpen(true)
            })
          } else {
            setMenuSideBarIsOpen(true)
          }
        }}
        onMenuSideBarClose={() => setMenuSideBarIsOpen(false)}
        menuSideBarIsOpen={menuSideBarIsOpen}
        activeSession={activeSession}
      >
        {menuSideBarIsOpen && (
          <div
            className={
              isFullScreen && !isMobile
                ? 'border-gray relative h-full w-72 border-r'
                : 'relative h-full w-full'
            }
          >
            <MenuSideBar
              personalizeActive={personalizeActive}
              onSetPersonalizeActive={() => {
                if (!isFullScreen || isMobile) {
                  setMenuSideBarIsOpen(false)
                }
                setPersonalizeActive(true)
              }}
              onExistingChatClick={(chatId) => {
                if (personalizationFormIsDirty) {
                  requestConfirmation(() => {
                    setPersonalizeActive(false)
                    setPersonalizationFormIsDirty(false)
                    loadSession(chatId)
                    if (!isFullScreen || isMobile) {
                      setMenuSideBarIsOpen(false)
                    }
                  })
                } else {
                  setPersonalizeActive(false)
                  loadSession(chatId)
                  if (!isFullScreen || isMobile) {
                    setMenuSideBarIsOpen(false)
                  }
                }

                setShowDraftMenu(false)
              }}
            />
            <div className="absolute bottom-0 left-0 right-0 flex h-12 items-center bg-white px-6 text-xs text-rb-gray-300">
              <a
                href="https://reforge.helpscoutdocs.com/article/191-reforge-ai"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about Reforge AI
              </a>
              <BasicInfoIcon className="ml-2 fill-rb-gray-300" fill="#757B74" />
            </div>
          </div>
        )}
        {(!menuSideBarIsOpen || (isFullScreen && !isMobile)) && (
          <Layout>
            {personalizeActive ? (
              <Personalize
                onCancel={handlePersonalizationCancel}
                onSave={handlePersonalizationSave}
                formIsDirty={personalizationFormIsDirty}
                setFormIsDirty={setPersonalizationFormIsDirty}
                personalizationData={personalizationData?.aiPersonalization}
                loading={dedupedTopicsAndFunctionsLoading || personalizationLoading}
                dedupedTopicsAndFunctions={
                  dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
                }
              />
            ) : (
              <>
                {showEditDraftView ? (
                  <DraftGenerationView
                    editor={editor}
                    headerTitle={editorHeaderTitle}
                    onCopy={() => {
                      const htmlContent = editor?.getHTML() ?? ''
                      const plainText = editor?.getText() ?? ''

                      const clipboardItem = new ClipboardItem({
                        'text/html': new Blob([htmlContent], { type: 'text/html' }),
                        'text/plain': new Blob([plainText], { type: 'text/plain' })
                      })
                      navigator.clipboard.write([clipboardItem])

                      trackCopiedText({
                        object_type: 'ai_draft',
                        related_identifiers: {
                          chat_session_id: chatId,
                          draft_type: activeDraft?.modeOptions?.label,
                          draft_id: activeDraft?.id
                        }
                      })

                      displayToast(
                        <ToastCard type="success" message="Copied to clipboard" />,
                        {
                          ...toastOptions,
                          toastId: 'reforge-toast'
                        }
                      )
                    }}
                    onClose={clearAndCloseEditDraftView}
                    onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                  >
                    <ChatContent
                      showEditDraftView={showEditDraftView}
                      showDraftMenu={showDraftMenu}
                      setShowDraftMenu={setShowDraftMenu}
                      isExpanded={isExpanded}
                      isFreeUser={isFreeUser}
                      handleMinimize={handleMinimize}
                      onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                      chatId={chatId}
                      messages={messages}
                      isLoading={isLoading}
                      loadingSession={loadingSession}
                      reload={reload}
                      recentChatsCount={recentChatsCount}
                      showPersonalizationCta={showPersonalizationCta}
                      dismissPersonalizationCta={dismissPersonalizationCta}
                      toggle={toggle}
                      setIsExpanded={setIsExpanded}
                      setPersonalizeActive={setPersonalizeActive}
                      mode={mode}
                      isMobile={isMobile}
                      sendMessage={sendMessage}
                      suggestedPrompts={suggestedPrompts}
                      suggestedPromptsLoading={suggestedPromptsLoading}
                      setMode={setMode}
                      stopGeneration={stopGeneration}
                      isSidePanelChatOpenDelayed={isSidePanelChatOpenDelayed}
                    />
                  </DraftGenerationView>
                ) : (
                  <ChatContent
                    showEditDraftView={showEditDraftView}
                    showDraftMenu={showDraftMenu}
                    setShowDraftMenu={setShowDraftMenu}
                    isExpanded={isExpanded}
                    isFreeUser={isFreeUser}
                    handleMinimize={handleMinimize}
                    onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                    chatId={chatId}
                    messages={messages}
                    isLoading={isLoading}
                    loadingSession={loadingSession}
                    reload={reload}
                    recentChatsCount={recentChatsCount}
                    showPersonalizationCta={showPersonalizationCta}
                    dismissPersonalizationCta={dismissPersonalizationCta}
                    toggle={toggle}
                    setIsExpanded={setIsExpanded}
                    setPersonalizeActive={setPersonalizeActive}
                    mode={mode}
                    isMobile={isMobile}
                    sendMessage={sendMessage}
                    suggestedPrompts={suggestedPrompts}
                    suggestedPromptsLoading={suggestedPromptsLoading}
                    setMode={setMode}
                    stopGeneration={stopGeneration}
                    isSidePanelChatOpenDelayed={isSidePanelChatOpenDelayed}
                  />
                )}
              </>
            )}
          </Layout>
        )}
      </ChatWrapper>
    </div>
  )
}

const DraftGenerationLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-row justify-center">
        <div className="flex h-full w-full flex-row break-words">{children}</div>
      </div>
    </div>
  )
}

const ChatViewLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-row justify-center">
        <div className="relative flex h-full w-full flex-col break-words md:max-w-3xl lg:max-w-4xl">
          {children}
        </div>
      </div>
    </div>
  )
}

type ChatContentProps = {
  isFreeUser: boolean
  showDraftMenu: boolean
  setShowDraftMenu: (showDraftMenu: boolean) => void
  handleMinimize: () => void
  onGeneratedDraftCtaClick: ({
    message,
    htmlString,
    title
  }: {
    message: Message
    htmlString: string
    title: string
  }) => void
  // extend with     chatId, messages, isLoading, loadingSession, reload, recentChatsCount, showPersonalizationCta, dismissPersonalizationCta, toggle, setIsExpanded, setPersonalizeActive, mode
  // from GlobalChatContext
} & Pick<
  GlobalChatUIProps,
  | 'chatId'
  | 'messages'
  | 'isLoading'
  | 'loadingSession'
  | 'reload'
  | 'recentChatsCount'
  | 'showPersonalizationCta'
  | 'dismissPersonalizationCta'
  | 'toggle'
  | 'setIsExpanded'
  | 'setPersonalizeActive'
  | 'mode'
  | 'isMobile'
  | 'sendMessage'
  | 'setMode'
  | 'stopGeneration'
  | 'suggestedPrompts'
  | 'suggestedPromptsLoading'
  | 'showEditDraftView'
  | 'isExpanded'
  | 'isSidePanelChatOpenDelayed'
>

const ChatContent: React.FC<ChatContentProps> = ({
  handleMinimize,
  onGeneratedDraftCtaClick,
  isFreeUser,
  chatId,
  messages,
  isLoading,
  loadingSession,
  reload,
  recentChatsCount,
  showPersonalizationCta,
  dismissPersonalizationCta,
  toggle,
  isExpanded,
  setIsExpanded,
  setPersonalizeActive,
  mode,
  isMobile,
  sendMessage,
  setMode,
  stopGeneration,
  suggestedPrompts,
  suggestedPromptsLoading,
  showEditDraftView,
  showDraftMenu,
  setShowDraftMenu,
  isSidePanelChatOpenDelayed
}) => {
  const { aiShowCommandMenu } = useFeatureFlags()
  const { trackChatDraftStarted } = useGlobalChatTracking()
  // NOTE: we might want to move this state into the GlobalChatContext
  const showCommandMenu =
    !!aiShowCommandMenu &&
    mode.mode === 'default' &&
    !loadingSession &&
    messages.length === 0

  const limitExceeded = useMemo(
    () => recentChatsCount >= NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN && isFreeUser,
    [recentChatsCount, isFreeUser]
  )

  const onSourceLinkClick = useCallback(() => {
    if (isMobile) {
      toggle({})
    }
    setIsExpanded(false)
  }, [setIsExpanded, isMobile, toggle])

  return (
    <>
      <div
        className={clsx(
          'relative hide-scrollbar h-full grow overflow-y-auto p-4',
          showEditDraftView ? 'px-0' : 'px-4'
        )}
      >
        <ChatFeed
          isFreeUser={isFreeUser}
          key={chatId}
          chatId={chatId}
          messages={messages}
          isLoading={isLoading}
          loadingSession={loadingSession}
          reload={reload}
          onSourceLinkClick={onSourceLinkClick}
          onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
          draftTemplateName={mode.modeOptions.template_name}
          suggestedPrompts={suggestedPrompts}
          suggestedPromptsLoading={suggestedPromptsLoading}
          sendMessage={sendMessage}
          mode={mode}
        />
      </div>
      {messages.length === 0 && !showDraftMenu && !loadingSession && (
        <div className="flex justify-center items-center text-xs text-rb-gray-200 mb-4">
          <a
            href="https://reforge.helpscoutdocs.com/article/191-reforge-ai"
            target="_blank"
            rel="noreferrer"
            className="hover:underline no-underline text-rb-gray-200 hover:text-rb-gray-300"
          >
            {"We don't share or train on your data"}
          </a>
          <BasicInfoIcon className="ml-1 fill-rb-gray-200" fill="#BBBDBA" />
        </div>
      )}

      {showDraftMenu ? (
        // maybe add flex here
        <DraftList
          isExpanded={isExpanded || isSidePanelChatOpenDelayed}
          onDraftClick={({ promptKey, draftName }) => {
            setShowDraftMenu(false)

            setMode({
              mode: 'document_generation',
              modeOptions: { template_name: promptKey, label: draftName }
            })

            sendMessage(`Help me draft a ${draftName} document`, {
              mode: 'document_generation',
              modeOptions: {
                command: 'draft',
                template_name: promptKey,
                label: draftName
              }
            })

            trackChatDraftStarted({
              chatId,
              templateName: draftName,
              location: 'in_chat'
            })
          }}
          onBackClick={() => {
            setShowDraftMenu(false)
            setMode({ mode: 'default', modeOptions: {} })
          }}
        />
      ) : (
        <div
          className={clsx(
            'flex w-full shrink-0 flex-grow flex-col overflow-hidden border-rb-gray-100 bg-white shadow-lg rounded-b-xl',
            {
              'border rounded-xl rounded-t-2xl mb-8': isExpanded,
              'rounded-xl rounded-t-2xl':
                (limitExceeded && !isExpanded) || showCommandMenu,
              'border-t': !isExpanded && !limitExceeded,
              'border rounded-xl': isSidePanelChatOpenDelayed
            }
          )}
        >
          {!limitExceeded && (
            <UserInput
              showCommandMenu={showCommandMenu}
              autofocus={true}
              adjustableHeight={true}
              chatId={chatId}
              isLoading={isLoading}
              sendMessage={sendMessage}
              setMode={setMode}
              setShowDraftMenu={setShowDraftMenu}
              mode={mode}
              stopGeneration={stopGeneration}
            />
          )}
          {showPersonalizationCta && !showCommandMenu && (
            <PersonalizationCta
              onClick={() => {
                dismissPersonalizationCta()
                setPersonalizeActive(true)
              }}
            />
          )}
          {isFreeUser && !showDraftMenu && (
            <MembershipCallout
              className="p-4 pr-6 transition-colors hover:no-underline bg-rb-blue-50"
              recentChatsCount={recentChatsCount}
              limitExceeded={limitExceeded}
              handleMinimize={handleMinimize}
            />
          )}
        </div>
      )}
    </>
  )
}

interface DraftGenerationViewProps {
  onCopy: () => void
  onClose: () => void
  headerTitle: string
  onGeneratedDraftCtaClick: ({
    message,
    htmlString,
    title
  }: OnGeneratedDraftCtaClickProps) => void
  children: React.ReactNode
  editor: EditorType | null
}

const DraftGenerationView = ({
  onCopy,
  onClose,
  headerTitle,
  children,
  editor
}: DraftGenerationViewProps) => {
  return (
    <>
      <div className="flex flex-col flex-1 h-full border-r py-4 border-rb-gray-50">
        <div className="flex justify-between">
          <div className="text-sm font-semibold text-rb-gray-300 ml-4 mb-3">
            {headerTitle}
          </div>
          <div className="flex flex-row justify-end gap-x-4">
            <button onClick={onCopy}>
              <CopyIcon className="w-[16px]" />
            </button>

            <button onClick={onClose}>
              <CloseIcon className="w-[16px]" />
            </button>
          </div>
        </div>
        <Editor editor={editor} />
      </div>
      <div className="flex flex-col h-full overflow-y-auto px-4 w-[400px]">
        <div className="hide-scrollbar h-full grow overflow-y-auto flex flex-col">
          {children}
        </div>
      </div>
    </>
  )
}
