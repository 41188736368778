import { cn } from 'utils/tailwind'

import { AiDocumentData, Message } from '../types'

export const INDUSTRY_OPTIONS = [
  'E-commerce',
  'Social',
  'Education',
  'Construction',
  'Food & Beverage',
  'Healthcare',
  'Advertising',
  'IT Services',
  'Real Estate',
  'Manufacturing',
  'Human Resources',
  'Consumer Products',
  'Energy',
  'Telecommunications',
  'Travel & Hospitality',
  'Information Security',
  'Government',
  'Logistics & Transportation',
  'Fintech',
  'Media',
  'Developer Tools',
  'Gaming',
  'Analytics'
]

export const getHeaders = () => ({
  'content-type': 'application/json',
  'x-csrf-token':
    document?.head?.querySelector('[name~=csrf-token]')?.getAttribute('content') || ''
})

const mostRecentMessage = ({
  messages,
  role
}: {
  messages: Message[]
  role: 'user' | 'assistant'
}) => {
  const userMessages = messages.filter((message) => message.role === role)

  if (userMessages.length > 0) {
    return userMessages[userMessages.length - 1]
  }

  return null
}

export const mostRecentAssistantMessage = ({ messages }: { messages: Message[] }) => {
  return mostRecentMessage({ messages, role: 'assistant' })
}

export const mostRecentUserMessage = ({ messages }: { messages: Message[] }) => {
  return mostRecentMessage({ messages, role: 'user' })
}

export const BASE_MESSAGE_BUBBLE_CLASSES = 'flex flex-col'

export const USER_MESSAGE_BUBBLE_CLASSES = cn(
  BASE_MESSAGE_BUBBLE_CLASSES,
  'ml-6 rounded-xl rounded-tr bg-rb-gray-50 px-4 py-2 text-rb-black/85'
)
export const ASSISTANT_MESSAGE_BUBBLE_CLASSES = cn(
  BASE_MESSAGE_BUBBLE_CLASSES,
  'text-rb-black/75'
)

export const getXRelativeToWindow = (gap = 0) => {
  const windowWidth = window.innerWidth
  const chatWidth = 400
  const x = windowWidth - chatWidth - gap
  return x
}

export const getSuggestedQuestionsFromMessage = (message: Message | null) => {
  if (
    !message ||
    message.role !== 'assistant' ||
    !message.data ||
    (Array.isArray(message) && message.data.length === 0)
  ) {
    return null
  }

  const hasSuggestedPrompts = message.data.find(
    (data) => data.type === 'suggestedPrompts'
  )

  if (!hasSuggestedPrompts) {
    return null
  }

  const questions = message.data
    .flatMap((data) => {
      if (data.type !== 'suggestedPrompts') {
        return null
      }

      return data.prompts.map((question) => question)
    })
    .filter((q: string) => !!q)

  if (questions.length === 0) {
    return null
  }

  return questions as string[] // we filter out the nulls above
}

export const getAiDocumentFromMessageData = (message: Message) => {
  if (!message.data) {
    return null
  }

  const hasAiDocument = message.data.find((data) => data.type === 'aiDocument')
  return (hasAiDocument as AiDocumentData)?.aiDocument
}
